<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="722"
    scrollable
  >
    <v-card :loading="isLoading" min-height="500">
      <v-card-title class="body-1 pa-0">
        <v-row class="ma-0 pa-0">
          <v-col class="pl-8" cols="auto">
            <h5 v-if="dataType">
              Upload {{csvItems.find((item) => item.value === dataType).label}} -  Pilih MoU ({{companyType}})
            </h5>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            class="ml-md-auto"
          >
            <v-text-field
              v-model="tempSearch"
              @keyup.native.enter="setSearch"
              dense
              hide-details
              outlined
              class="caption"
              placeholder="Cari perusahaan"
            >
              <template v-slot:prepend-inner>
                <v-icon @click="setSearch">
                  mdi-magnify
                </v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="customerItems"
          :items-per-page="1000"
          :loading="isLoadingGetList"
          hide-default-footer
        >
          <template v-slot:[`item.action`]={item}>
            <v-radio-group
              v-model="mouId"
              column
            >
              <v-radio
                color="primary"
                :value="item.id"
              ></v-radio>
            </v-radio-group>
          </template>
        </v-data-table>
        <v-row>
          <v-card v-intersect="infiniteScrolling"></v-card>
          <v-progress-circular
            v-if="isLoadingMoreData"
            indeterminate
            color="primary"
            class="w-100 mt-10 mb-10"
          ></v-progress-circular>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row
          justify="end"
          class="ma-0"
        >
          <v-col
            cols="12"
            sm="3"
          >
            <v-btn
              color="red"
              class="white--text"
              small
              block
              @click="dialog = false"
            >
              {{$_strings.common.CANCEL}}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <v-btn
              color="primary"
              class="white--text"
              small
              block
              :disabled="!mouId"
              @click="submit"
            >
              {{$_strings.common.SELECT}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { skipEmptyStringObject } from '@/helper/commonHelpers';

export default {
  props: {
    mouBy: {
      type: String,
      default: () => '',
    },
    dataType: {
      type: String,
      default: () => '',
    },
    csvItems: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    mouBy() {
      this.customerItems = [];
    },
    dialog(newVal) {
      if (newVal && !this.customerItems.length) this.fetchMoUList('isLoadingGetList');
    },
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      isLoadingGetList: false,
      isLoadingMoreData: false,
      mouId: null,
      tempSearch: '',
      search: '',
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'action',
        },
        {
          text: 'Nomor MoU',
          align: 'start',
          sortable: false,
          value: 'mouNo',
        },
        {
          text: 'Nama Perusahaan',
          align: 'start',
          sortable: false,
          value: 'companyName',
        },
        {
          text: 'Alias/inisial',
          align: 'start',
          sortable: false,
          value: 'abbreviation',
        },
      ],
      customerItems: [],
      page: 1,
    };
  },
  computed: {
    companyType() {
      return this.mouBy === 'shipper' ? 'Shipper' : 'Transporter';
    },
  },
  methods: {
    skipEmptyStringObject,
    setSearch(e) {
      if (e.type === 'click' || e.keyCode === 13) {
        this.search = this.tempSearch;
        this.page = 1;
        this.customerItems = [];
        this.fetchMoUList('isLoadingGetList');
      }
    },
    infiniteScrolling(entries, observer, isIntersecting) {
      if (isIntersecting
        && !this.isLoadingMoreData
        && !this.isLoadingGetList
        && this.customerItems.length < this.totalData
      ) {
        this.page += 1;
        this.fetchMoUList();
      }
    },
    async fetchMoUList(isLoading = 'isLoadingMoreData') {
      const filters = {
        page: this.page - 1,
        size: 10,
        companyName: this.search,
        activeStatus: 'DRAFT',
      };
      try {
        this[isLoading] = true;
        const service = this.mouBy === 'shipper' ? this.$_services.mou.fetchMouShipper : this.$_services.mou.fetchMouTransporter;
        const result = await service(this.skipEmptyStringObject(filters));
        this.customerItems = [...this.customerItems, ...result.data.contents];
        this.totalData = result.data.totalData;
      } finally {
        this[isLoading] = false;
      }
    },
    submit() {
      this.$emit('handleUploadCsv', this.mouId);
      this.dialog = false;
    },
  },
};
</script>
